import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"

const NotFoundPage = () => {

  if (typeof window !== 'undefined') {
    window.location = '/';
  }

  return (<Layout>
    <Seo title="Greener - Architektura Zieleni - Poznań Gniezno" />
    <h1>Strona nie została znaleziona.</h1>
  </Layout>)
}

export default NotFoundPage